var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "fill-height": "", wrap: "" } },
        [
          _c(
            "v-container",
            {
              staticClass: "mx-auto",
              staticStyle: { width: "90%", "max-width": "450px" }
            },
            [_vm._v(_vm._s(_vm.$t("error.tokenCheckError")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }